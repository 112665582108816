<template>
	<div>
	
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
							style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-6">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h1>Ergo equipment guide<br />
																				Keyboard and Mouse</h1>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-6">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<p>
																				<router-link :to="{ name: 'chairs-a45' }">
																						<em>Up
																						Next: </em> Chairs <img
																						class="vc_single_image-img attachment-full"
																						src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
																						alt="" width="64"
																						height="37" />
																				</router-link>
																			</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_raw_code wpb_content_element wpb_raw_html">
													<div class="wpb_wrapper">
														<div class="equipment_image_wrapper">
															<img src="../../assets/images/airbnb/Airbnb-Equipment-Main-Page.jpg"
																alt="Equipment Guides" />
															<router-link :to="{ name: 'mobile-devices-a45' }" id="equipment-icon-mobile-devices" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Mobile devices" />
															</router-link>

															<router-link :to="{ name: 'screens-a45' }" id="equipment-icon-screens" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Screens" />
															</router-link>

															<router-link :to="{ name: 'keyboard-mouse-eq-a45' }" id="equipment-icon-keyboard-mouse" class="equipment-icon current">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Keyboard and Mouse" />
															</router-link>

															<router-link :to="{ name: 'chairs-a45' }" id="equipment-icon-chairs" class="equipment-icon flipped">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Chairs" />
															</router-link>

															<router-link :to="{ name: 'lighting-a45' }" id="equipment-icon-lighting" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Lighting" />
															</router-link>

															<router-link :to="{ name: 'ergo-accessories-a45' }" id="equipment-icon-ergo-accessories" class="equipment-icon flipped">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Ergo accessories" />
															</router-link>


															<div class="equipment-desc">
																<h3>Keyboard &amp; Mouse</h3>
																<ul>
																	<li>Place at elbow height</li>
																	<li>Keep close to your body</li>
																	<li>Keep keyboard flat</li>
																	<li>Mouse level with keyboard</li>
																</ul>
															</div>

														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner "
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left   rounded">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="700" height="411"
																					src="../../assets/images/airbnb/Airbnb-Equipment-Keyboard-Mouse-1.jpg"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipment---Keyboard-Mouse" />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-8">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h2>How do you keyboard and mouse?</h2>
																			<p>No matter what type of keyboard or mouse
																				you prefer to work with, it is important
																				to be mindful of how you use them.</p>
																			<p>Do you touch type?</p>
																			<p>Do you rest your wrists on a wrist rest
																				or desk edge?</p>
																			<p>Do you pivot your wrists from side to
																				side?</p>
																			<p>The tips below will help you feel great
																				when you work.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  two_cols"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="82" height="50"
																					src="../../assets/images/airbnb/Airbnb-Equipment-Keyboard-and-Mouse-Set-up-1.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipment---Keyboard-and-Mouse-Set-up" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637040142698">
																		<div class="wpb_wrapper">
																			<h4>Keyboard Set-up</h4>
																			<p>&nbsp;</p>
																			<ul>
																				<li>Use your keyboard at or slightly
																					below your elbow height.</li>
																				<li>Keep your keyboard in front of you
																					and as close as possible.</li>
																				<li>Adjust your keyboard to be as flat
																					as possible.</li>
																				<li>If you use a wrist rest, use it for
																					resting in between typing.</li>
																			</ul>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="33" height="50"
																					src="../../assets/images/airbnb/Airbnb-Equipment-Keyboard-and-Mouse-Mouse-Set-up.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipment---Keyboard-and-Mouse---Mouse-Set-up" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637040160427">
																		<div class="wpb_wrapper">
																			<h4>Mouse Set-up</h4>
																			<p>&nbsp;</p>
																			<ul>
																				<li>Keep your mouse level with your
																					keyboard.</li>
																				<li>Keep it as close to you as possible.
																				</li>
																				<li>It is best to position your mouse
																					front of your shoulder.</li>
																				<li>Make sure your mouse is the right
																					fit for your hand.</li>
																			</ul>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="44" height="50"
																					src="../../assets/images/airbnb/Airbnb-Equipment-Keyboard-and-Mouse-Hand-Savers.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipment---Keyboard-and-Mouse---Hand-Savers" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637040178573">
																		<div class="wpb_wrapper">
																			<h4>Hand Savers</h4>
																			<p>&nbsp;</p>
																			<ul>
																				<li>Keep your fingers in their natural
																					curve.</li>
																				<li>Keep your wrists from bending back.
																				</li>
																				<li>Avoid twisting your wrists (pivoting
																					side to side)</li>
																				<li>Keep your typing force and mouse
																					grip light.</li>
																			</ul>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="vc_row wpb_row section vc_row-fluid  grid_section" style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h2>All equipment guides</h2>

													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'screens-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="700" height="467"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Screens-Main-Page.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-Equipment---Screens---Main-Page" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'screens-a45'}">
																			<h4>Screens</h4>
																			<p>Wanna have great posture and be
																				productive? Learn how.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'keyboard-mouse-eq-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="700" height="600"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Keyboard-Mouse.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment---Keyboard-Mouse" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'keyboard-mouse-eq-a45'}">
																			<h4>Keyboard &amp; mouse</h4>
																			<p>Discover the best technique to mouse and
																				type.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'chairs-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="700" height="467"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Chairs.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment---Chairs" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'chairs-a45'}">
																			<h4>Chairs</h4>
																			<p>Ergo has your back no matter where you
																				sit.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'lighting-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Equipmnet-Lighting.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipmnet---Lighting" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'lighting-a45'}">
																			<h4>Lighting</h4>
																			<p>Learn how to use light to improve your
																				output.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'ergo-accessories-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Accessories.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment-Accessories" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'ergo-accessories-a45'}">
																			<h4>Ergo accessories</h4>
																			<p>It’s all in the details. Learn how
																				accessories help.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'mobile-devices-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="501"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Mobile-Devices.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment---Mobile-Devices" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'mobile-devices-a45'}">
																			<h4>Mobile devices</h4>
																			<p>Ergo includes your mobile devices. Learn
																				more.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



					</div>
				</div>
				

			</div>
		</div>
	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'keyboard-mouse-eq-a45',
	}
</script>